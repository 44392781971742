import type { Invoice } from '@orus.eu/backend/src/views/invoicing/invoice-model'
import { PARIS, getZonedDateTimeFromMillis } from '@orus.eu/calendar-date'
import { formatDate } from './format'

export function getInvoicePeriodLabel(invoice: Invoice): string {
  return getInvoicePeriodLabelFromTimestamps('items' in invoice ? invoice.items[0] : invoice)
}

export type PeriodLabelParams = {
  periodStartTimestamp: number
  periodEndTimestamp: number
}

export function getInvoicePeriodLabelFromTimestamps(params: PeriodLabelParams): string {
  const { periodStartTimestamp, periodEndTimestamp } = params
  const start = formatDate(periodStartTimestamp)
  const end = formatDate(
    getZonedDateTimeFromMillis(periodEndTimestamp, PARIS)
      .minus({ day: 1 }) // we want the last day to be included
      .toMillis(),
  )
  return `${start} au ${end}`
}

export function getInvoicePeriodLabelFromTimestampsV2(params: PeriodLabelParams): string {
  const { periodStartTimestamp, periodEndTimestamp } = params
  const start = formatDate(periodStartTimestamp)
  const end = formatDate(getZonedDateTimeFromMillis(periodEndTimestamp, PARIS).toMillis())
  return `${start} au ${end}`
}
