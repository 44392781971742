import { css } from '@emotion/react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  type SxProps,
} from '@mui/material'
import type { Amount } from '@orus.eu/amount'
import type { PaymentStatus } from '@orus.eu/backend/src/events/payment-update'
import type { PaymentProviderName } from '@orus.eu/backend/src/events/stripe-object-update'
import type { InvoicingItem } from '@orus.eu/backend/src/views/invoicing/invoice-payment-status-view'
import { Button, ButtonLink, colors, spacing, useAsyncCallback, type ButtonVariant } from '@orus.eu/pharaoh'
import { useState } from 'react'
import { trpc } from '../../../client'
import { formatDateTime } from '../../../lib/format'
import { getInvoicePeriodLabel, getInvoicePeriodLabelFromTimestamps } from '../../../lib/invoice-util'
import { getGocardlessPaymentIntentUrl, getStripePaymentIntentUrl } from '../../../lib/payment-provider-util'
import { useSession } from '../../../lib/session'
import { oneMinute, useCurrentTimestamp } from '../../../lib/use-current-timestamp'
import { usePermissions } from '../../../lib/use-permissions'
import { TableCellAmount } from '../../molecules/table-elements/table-cell-amount'
import { TableCellHeader } from '../../molecules/table-elements/table-cell-header'
import { TableCellInvoicingItemStatusTag } from '../../molecules/table-elements/table-cell-invoicing-item-status-tag'
import { TableCellText } from '../../molecules/table-elements/table-cell-text'
import { CancelInvoiceButton } from './cancel-invoice-button'
import { DeleteInvoiceButton } from './delete-invoice-button'
import { InvoiceDocumentsDownloadButtons } from './invoice-documents-download-buttons'
import { DraftInvoiceStatusTag } from './invoicing-item-status-tag'
import { ManualPaymentButton } from './ManualPaymentButton'
import { PayInvoiceButton } from './pay-invoice-button'
import { ValidateInvoiceButton } from './validate-invoice-button'
type InvoiceListProps = {
  invoicingItems: InvoicingItem[]
  /**
   * Allow displaying a draft preview row. Leave undefined to have no preview row.
   * Set to null to have a blank preview row.
   * Set to a non-null value to have a filled draft preview row.
   */
  draftPreviewRow?: DraftPreviewRowProps | null
  sx?: SxProps
}

export function InvoiceList(props: InvoiceListProps): JSX.Element {
  const { invoicingItems, draftPreviewRow, sx } = props
  const orderedInvoicingItems = [...invoicingItems]
  orderedInvoicingItems.sort((a, b) => {
    return b.invoice.dueTimestamp - a.invoice.dueTimestamp
  })
  const session = useSession()
  const { permissions } = usePermissions()

  return (
    <TableContainer sx={sx}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCellHeader>Due le</TableCellHeader>
            <TableCellHeader>Période</TableCellHeader>
            <TableCellHeader>Statut</TableCellHeader>
            <TableCellHeader>Montant</TableCellHeader>
            <TableCellHeader>Document</TableCellHeader>
            {session.permissions.type === 'platform' && permissions.includes('invoices.create') ? (
              <TableCellHeader>Action</TableCellHeader>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {draftPreviewRow === null ? <BlankPreviewRow /> : <></>}
          {draftPreviewRow ? <DraftPreviewRow {...draftPreviewRow} /> : <></>}
          {orderedInvoicingItems.map((invoicingItem) => (
            <InvoiceListRow key={invoicingItem.invoice.invoiceId} invoicingItem={invoicingItem} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

type InvoiceListRowProps = {
  invoicingItem: InvoicingItem
}

function InvoiceListRow({ invoicingItem }: InvoiceListRowProps): JSX.Element {
  const { invoice, type } = invoicingItem
  const invoiceId = invoice.invoiceId
  const [synchronizing, setSynchronizing] = useState(false)
  const session = useSession()
  const { permissions } = usePermissions()

  const synchronizeInvoicePaymentStatus = useAsyncCallback(async () => {
    setSynchronizing(true)
    await trpc.invoices.synchronizeInvoicePaymentAttempts.mutate(invoiceId)
    document.location.reload()
  }, [invoiceId])

  let lastTentativePaymentIntentId: string | undefined
  let lastTentativePaymentProvider: PaymentProviderName | undefined
  if (type === 'emitted') {
    lastTentativePaymentIntentId = invoicingItem.lastPaymentTentativeIntentId
    lastTentativePaymentProvider = invoicingItem.lastPaymentTentativeProvider
  }

  return (
    <TableRow key={invoiceId}>
      <TableCellText text={formatDateTime(invoice.dueTimestamp)} />
      <TableCellText text={getInvoicePeriodLabel(invoice)} />
      <TableCellInvoicingItemStatusTag invoicingItem={invoicingItem} />
      <TableCellAmount amount={invoice.totalPremium} />
      <TableCell>
        <InvoiceDocumentsDownloadButtons invoicingItem={invoicingItem} />
      </TableCell>
      {session.permissions.type === 'platform' && permissions.includes('invoices.create') ? (
        <TableCell>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: ${spacing[50]};
            `}
          >
            <InvoiceActionValidate invoicingItem={invoicingItem} />
            <InvoiceActionDelete invoicingItem={invoicingItem} />
            <InvoiceActionMarkAsPaid invoicingItem={invoicingItem} />
            <InvoiceActionCancel invoicingItem={invoicingItem} />
            {permissions.includes('stripe.read') ? (
              <Button
                disabled={synchronizing}
                variant="secondary"
                size="small"
                icon="arrows-rotate-regular"
                title="Synchroniser le statut de paiement"
                onClick={synchronizeInvoicePaymentStatus}
              ></Button>
            ) : null}
            <InvoiceActionPay invoicingItem={invoicingItem} />
            {lastTentativePaymentIntentId && permissions.includes('stripe.read') ? (
              <PaymentProviderLink
                variant="secondary"
                provider={lastTentativePaymentProvider}
                paymentIntentId={lastTentativePaymentIntentId}
              />
            ) : null}
          </div>
        </TableCell>
      ) : null}
    </TableRow>
  )
}

export type DraftPreviewRowProps = {
  dueTimestamp: number
  periodStartTimestamp: number
  periodEndTimestamp: number
  totalPremium: Amount
}

function DraftPreviewRow(props: DraftPreviewRowProps): JSX.Element {
  return (
    <TableRow
      sx={{
        backgroundColor: colors.yellow[200],
        outline: `2px solid ${colors.red[500]}`,
      }}
    >
      <TableCellText text={formatDateTime(props.dueTimestamp)} />
      <TableCellText text={getInvoicePeriodLabelFromTimestamps(props)} />
      <TableCell>
        <DraftInvoiceStatusTag />
      </TableCell>
      <TableCellAmount amount={props.totalPremium} />
      <TableCell />
      <TableCell />
    </TableRow>
  )
}

function BlankPreviewRow(): JSX.Element {
  return (
    <TableRow>
      <TableCell colSpan={6}>
        <Typography
          component="p"
          sx={{
            color: colors.gray[200],
            textAlign: 'center',
            padding: '2px', // have same height as rows with tags
          }}
        >
          Un aperçu sera généré une fois tous les champs remplis !
        </Typography>
      </TableCell>
    </TableRow>
  )
}

type InvoiceActionProps = {
  invoicingItem: InvoicingItem
}

function InvoiceActionPay({ invoicingItem }: InvoiceActionProps): JSX.Element {
  const currentTimestamp = useCurrentTimestamp(oneMinute)

  if (
    invoicingItem.type === 'emitted' &&
    invoicingItem.invoice.status === 'valid' &&
    payableInvoiceStatus[invoicingItem.paymentStatus] &&
    invoicingItem.invoice.dueTimestamp <= currentTimestamp
  ) {
    return (
      <PayInvoiceButton invoiceId={invoicingItem.invoice.invoiceId} confirmText="Tentative de paiement lancée">
        Payer
      </PayInvoiceButton>
    )
  }

  return <></>
}

function InvoiceActionMarkAsPaid({ invoicingItem }: InvoiceActionProps): JSX.Element {
  const currentTimestamp = useCurrentTimestamp(oneMinute)

  if (
    invoicingItem.type === 'emitted' &&
    invoicingItem.invoice.status === 'valid' &&
    invoicingItem.invoice.dueTimestamp <= currentTimestamp
  ) {
    return (
      <ManualPaymentButton
        invoiceId={invoicingItem.invoice.invoiceId}
        targetStatus={invoicingItem.paymentStatus === 'paid' ? 'failed' : 'paid'}
      />
    )
  }

  return <></>
}

function InvoiceActionValidate({ invoicingItem }: InvoiceActionProps): JSX.Element {
  if (invoicingItem.type === 'draft') {
    return <ValidateInvoiceButton invoiceId={invoicingItem.invoice.invoiceId}>Valider</ValidateInvoiceButton>
  }

  return <></>
}

function InvoiceActionDelete({ invoicingItem }: InvoiceActionProps): JSX.Element {
  if (invoicingItem.type === 'draft') {
    return <DeleteInvoiceButton invoiceId={invoicingItem.invoice.invoiceId} />
  }

  return <></>
}

function InvoiceActionCancel({ invoicingItem }: InvoiceActionProps): JSX.Element {
  if (
    invoicingItem.type === 'emitted' &&
    ((invoicingItem.invoice.status === 'valid' && invoicingItem.paymentStatus === 'new') ||
      invoicingItem.paymentStatus === 'failed' ||
      invoicingItem.paymentStatus === 'cancelled' ||
      invoicingItem.paymentStatus === 'refunded')
  ) {
    return <CancelInvoiceButton invoiceId={invoicingItem.invoice.invoiceId} />
  }

  return <></>
}

export function PaymentProviderLink(props: {
  paymentIntentId: string
  provider: PaymentProviderName | undefined
  variant?: ButtonVariant
  showCancelAction?: boolean
}): JSX.Element {
  const { paymentIntentId, provider, variant } = props

  return (
    <ButtonLink
      size="small"
      variant={variant}
      icon="arrow-up-right-from-square-regular"
      avatarPosition="right"
      to={
        provider === 'gocardless'
          ? getGocardlessPaymentIntentUrl(paymentIntentId)
          : getStripePaymentIntentUrl(paymentIntentId)
      }
      target="_blank"
    >
      {provider === 'gocardless' ? 'GoCardless' : 'Stripe'}
    </ButtonLink>
  )
}

const payableInvoiceStatus: { [key in PaymentStatus]: boolean } = {
  new: true,
  failed: true,
  cancelled: true,
  refunded: true,
  disputed: false,
  paid: false,
  pending: false,
}
